//
// user.scss
// Use this to write your custom SCSS
//

.App {
  height: 100vh;
  padding-left: 0; // override bootstrap
  padding-right: 0; // override bootstrap

  // force app height to match screen height
  > .row {
    height: 100%;
    margin-left: 0;
    margin-right: 0;

    > .col {
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.main-content {
  height: 100%;
}
